import React, { useEffect } from "react";
import { Checkbox, Chip, FormControl, FormControlLabel, Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

export default function AthenaSyncForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, displayHeader }) {
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      partial_sync: taskConfig.partial_sync ?? true,
      study: studyType }));
  }, [setTaskConfig, studyType, taskConfig.partial_sync]);

  const deploymentEnv = process.env.REACT_APP_CUSTOM_NODE_ENV;

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["file_type"])} />
      </div>
      <Typography variant="h6">Athena Data Sync</Typography>
      <Typography variant="subtitle1" style={{ margin: 15 }}>
        {"This job will sync Production Athena data to "}
        <b>{`${deploymentEnv}`}</b>
        <br />
      </Typography>
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormControlLabel
          control={(
            <Checkbox
              value="partial_sync"
              checked={taskConfig.partial_sync ?? true}
              onChange={(e) => setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, partial_sync: e.target.checked }))}
            />
          )}
          label="Partial Sync (Extra tables not in prod will not be wiped)"
        />
      </FormControl>
      {children}
    </Form>
  );
}
