import React, { useEffect } from "react";
import { Alert, List, ListItem, Typography, Chip } from "@mui/material";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

export default function AnnMisightQAReportForm({ children, submitHandler, client, displayHeader, studyType, taskConfig, setTaskConfig }) {
  useEffect(() => {
    setTaskConfig((prevConfig) => ({ ...prevConfig, study: studyType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskConfig]);
  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["file_type"])} />
      </div>
      <Alert severity="info">
        Please make sure that the ANN OTT (DD) report has been run and confirmed with the following config
        {" "}
        <b>before</b>
        {" "}
        running this report
        <List sx={{ listStyleType: "disc" }}>
          <ListItem sx={{ display: "list-item" }}>
            {"\u2022 \t 'Territory Group Map as Territory' is selected as "}
            <b>{`${client}:WHOLESALER .`}</b>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            {"\u2022 \t 'Include in MISIGHT QA' option has been checked with 'OTT Type' selected as Territory."}
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            {"\u2022 \t Make Sure the End Quarter/Production cycle is same for the ANN OTT (DD) and ANN Publish consortium table (DD) tasks that have been run."}
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            {"\u2022 \t Make sure the ANN OTT (DD) report has been run for "}
            <b>rolling 4 quarters</b>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            {"\u2022 \t Make Sure that ANN Create Materialized Client View (DD) has been run for "}
            {`${client} with the latest quarter`}
          </ListItem>
        </List>
        <br />
        <b>NOTE : </b>
        {" "}
        The Sales and Opportunities Sales values in this are aggregated for the latest rolling 4 quarters
      </Alert>
      {children}
    </Form>
  );
}
