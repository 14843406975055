import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import OptionSelect from "../../components/input/OptionSelect";

const databaseTypes = [
  { id: "mi_integration", label: "mi-integration Aurora PostgreSQL DB" },
  { id: "lms_mysql", label: "LMS Aurora MySQL DB" },
];

export default function AuroraSyncForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  useEffect(() => {
    setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, study: studyType }));
    setIsRequired((prevState) => ([...prevState, "database"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="subtitle1" style={{ margin: 15 }}>
        This Job makes the Target DB the same as the Prod DB. This job cannot be done in the Production environment.
      </Typography>
      <OptionSelect
        required
        style={{ width: 500 }}
        value={taskConfig.database || ""}
        onChange={(database) => {
          setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, database }));
        }}
        options={databaseTypes}
        label="Select Database to Sync"
      />
      {children}
    </Form>
  );
}
