import React, { useEffect, useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Box from "@mui/material/Box";

import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import TableLoadingOverlay from "../../../components/MaterialTableLoadingOverlay";
import { textPerPalette } from "../../../theme/common";
import Table from "./Table";

function isNumeric(str) {
  if (typeof str !== "string") return true; // we only process strings!
  return (
    !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    && !Number.isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

const DescriptionRenderer = (obj) => {
  const { value } = obj;

  const [boldText, normalText] = value.split(":");
  return (
    <>
      <strong>{boldText}</strong>
      <>
        :
        {normalText}
      </>
    </>
  );
};

function QaAuditChecklist(data) {
  const [columnDefs, setColumnDefs] = useState([]);
  const { inputData } = data;
  const [header, setHeader] = useState([]);
  const [quarter, setQuarter] = useState([]);
  const [quarterSelectionExpanded, setQuarterSelectionExpanded] = useState(true);
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);
  const theme = useTheme();

  const onChangeCallback = (e, element) => {
    setColumnDefs(
      columnDefs.map((column) => {
        if (column.field === element.field) {
          return {
            ...element,
            hide: e.target.checked ? "false" : "true",
          };
        }
        return column;
      }),
    );
  };

  useEffect(() => {
    if (inputData) {
      setHeader(Object.keys(inputData.data?.[0]));
      setQuarter(
        Object.keys(inputData.data?.[0]).filter((item) => item.includes("Q")),
      );
    }
  }, [inputData]);

  const current4Cycles = useSelector((state) => state.formOptions.productionCycle.slice(1, 5));
  useEffect(() => {
    const cellClassRules = {
      "cell-pass": (params) => params.value === "Better",
      "cell-fail": (params) => params.value === "Worse",
      "cell-stable": (params) => params.value === "Stable",
      "cell-negative": (params) => (params.value?.toString().includes("($")),
    };

    const tempColDef = header.map((keyItem) => {
      if (isNumeric(keyItem.slice(0, 4))) {
        return {
          headerName: keyItem.toUpperCase(),
          field: keyItem,
          initialHide: !current4Cycles.includes(keyItem.toUpperCase()),
          minWidth: "115",
          cellClassRules,
          cellStyle: { textAlign: "center" },
          headerClass: "header-text-center",
        };
      }
      let returnValue = {};
      switch (keyItem) {
      case "Description":
        returnValue = {
          field: "Description",
          minWidth: "300",
          maxWidth: "300",
          initialHide: false,
          wrapText: true,
          autoHeight: true,
          cellRenderer: DescriptionRenderer,
          headerClass: "header-text-center",
        };
        break;

      case "Current":
        returnValue = {
          field: "Current",
          minWidth: "115",
          initialHide: false,
          cellClassRules,
          cellStyle: { textAlign: "center" },
          headerClass: "header-text-center",
        };
        break;

      case "Status":
        returnValue = {
          field: "Status",
          minWidth: "90",
          initialHide: false,
          maxWidth: "120",
          cellClassRules,
          cellStyle: { textAlign: "center" },
          headerClass: "header-text-center",
        };
        break;

      default:
        break;
      }
      return returnValue;
    });
    setColumnDefs(tempColDef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quarter]);

  return columnDefs?.length > 0 && inputData?.data?.length > 0 ? (
    <>
      <Accordion
        expanded={quarterSelectionExpanded}
        onChange={() => setQuarterSelectionExpanded(!quarterSelectionExpanded)}
        square={false}
        style={{ borderRadius: "10px", marginBottom: 20, marginTop: 20 }}
      >
        <Card
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            backgroundColor: theme.palette.info.main,
            height: "auto",
            justifyItems: "center",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: textPerPalette(theme) }} />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <Typography variant="h6" component="h3" style={{ color: textPerPalette(theme) }}>
              QA Audit Checklist
            </Typography>
          </AccordionSummary>
        </Card>

        <Grid container spacing={1} alignItems="center" justifyContent="space-evenly">
          {columnDefs.map((element) => {
            if (element.field?.includes("Q")) {
              return (
                <Grid item xs={1} key={element.field}>
                  <FormControlLabel
                    label={<Typography variant="h6" component="h2">{element.field}</Typography>}
                    key={element.field}
                    control={(
                      <Checkbox
                        key={element.field}
                        checked={element.hide ? element.hide === "false" : element.initialHide === false}
                        onChange={(e) => onChangeCallback(e, element)}
                      />
                    )}
                    style={{ fontStyle: "bold", margin: "5px" }}
                  />
                </Grid>
              );
            }
            return <div key={element.field} />;
          })}
        </Grid>
      </Accordion>
      <div>
        <Table inputData={inputData} columnDefs={columnDefs} />
      </div>
      <p />
      <Typography component="div">
        <Box fontWeight="bold">Notes and Explanations:</Box>
        <Box>
          *Calculations against the &quot;reportable dataset&quot; in this
          Audit Control do not consider Phantom Exclusions (data exclusions
          applied to finalized client outputs) due to the low impact upon the
          overall dataset and the outdated data structure that results in undue
          logic complexity and performance impacts for QA development.
        </Box>
        <Box>
          Volume cannot expect to meet 100% threshold as clients submit data to
          standardized addresses which are verified to be false addresses.
        </Box>
        <Box fontWeight="bold">Definitions</Box>
        <Box fontWeight="bold">Data Conversion:</Box>
        <Box>
          Quality controls focused upon measuring the
          transformation of the client-submitted raw dataset to the ISS-curated
          reportable dataset.
        </Box>
        <Box fontWeight="bold">Data Standardization:</Box>
        <Box>
          Quality controls focused upon measuring
          the ISS-designed process for grouping client-submitted values and
          displaying a standardized representation with aggregated volumes
          associated.
        </Box>
        <Box fontWeight="bold">Raw Volume:</Box>
        <Box>Client-submitted volume prior to any ISS-determined exclusions</Box>
        <Box fontWeight="bold">Reportable Volume:</Box>
        <Box>
          Reportable Volume: ISS-curated volume following the data move to
          reporting tables. Inclusive of exclusions applied to outputs.
        </Box>

      </Typography>

    </>
  ) : (memoizedLoadingOverlay);
}

export default QaAuditChecklist;
