import ANNDashboardForm from "../../../forms/JsonSchema/ANNDashboard";
import AnnInternalTerrConflictFromMapForm from "../../../forms/JsonSchema/ANNInternalTerrConflictFromMap";
import AnnInternalTerrConflictFromMapFormDD from "../../../forms/JsonSchema/ANNInternalTerrConflictFromMapDD";
import AnnSiIdentifierMappingForm from "../../../forms/JsonSchema/ANNSiIdentifierMapping";
import ANNStandardFeedForm from "../../../forms/JsonSchema/ANNStandardFeed";
import ANNOTTForm from "../../../forms/JsonSchema/AnnOTT";
import CreateAthenaLockPartitionForm from "../../../forms/JsonSchema/CreateAthenaLockPartition";
import AnnGPSForm from "../../../forms/JsonSchema/GrowthPotentialScore";
import MISightPrewarmCacheForm from "../../../forms/JsonSchema/MISightPrewarmCache";
import PublishANNLegacyForm from "../../../forms/JsonSchema/PublishANNLegacy";
import PublishAthenaConsortiumForm from "../../../forms/JsonSchema/PublishAthenaConsortium";
import PublishAthenaConsortiumFormDD from "../../../forms/JsonSchema/PublishAthenaConsortiumDD";
import PublishAthenaTerritoryFormDD from "../../../forms/JsonSchema/PublishAthenaTerritoryDD";

import ANNLMSQADashboardForm from "../../../forms/Regular/ANNLMSQADashboardForm";
import ANNLoadChannelsFormDD from "../../../forms/Regular/ANNLoadChannelsFormDD";
import ANNLoadOfficeMapFormDD from "../../../forms/Regular/ANNLoadOfficeMapFormDD";
import ANNLoadTerritoryMapsFormDD from "../../../forms/Regular/ANNLoadTerritoryMapsFormDD";
import ANNMisightQADashboardForm from "../../../forms/Regular/ANNMisightQADashboardForm";
import AnnMisightQAReportForm from "../../../forms/Regular/ANNMisightQAReport";
import ANNProductNameBreakdownForm from "../../../forms/Regular/ANNProductNameBreakdown";
import ANNSalesTotalsSelectivelyDD from "../../../forms/Regular/ANNSalesTotalsSelectivelyDD";
import AddNewClient from "../../../forms/Regular/AddNewClient";
import AnnGeneralDataDumpDD from "../../../forms/Regular/AnnGeneralDataDumpDD";
import AnnLoadShareClassStandardsForm from "../../../forms/Regular/AnnLoadShareClassStandardsForm";
import AnnLoadVAProductTypesDD from "../../../forms/Regular/AnnLoadVAProductTypesDD";
import AnnMFClientMaterializedViewForm from "../../../forms/Regular/AnnMFClientMaterializedViewForm";
import AnnVABDChannelConflictsForm from "../../../forms/Regular/AnnVABDChannelConflictsForm";
import AnnVABDChannelConflictsFormDD from "../../../forms/Regular/AnnVABDChannelConflictsFormDD";
import AthenaSyncForm from "../../../forms/Regular/AthenaSync";
import AuroraSyncForm from "../../../forms/Regular/AuroraSyncForm";

import ClientMaterializedViewFormDD from "../../../forms/Regular/ClientMaterializedViewFormDD";
import DDMatchingQAReport from "../../../forms/Regular/DDMatchingQaReport";
import DDMissingBranches from "../../../forms/Regular/DDMissingBranches";
import DDSyncForm from "../../../forms/Regular/DDSyncForm";
import DatasetLockingForm from "../../../forms/Regular/DatasetLocking";
import GenericExporterForm from "../../../forms/Regular/GenericExporterForm";
import GenericLoaderFormDD from "../../../forms/Regular/GenericLoadersFormDD";
import LMSDDCrossReference from "../../../forms/Regular/LMSDDCrossReference";
import LoadAnnSalesForm from "../../../forms/Regular/LoadAnnSalesForm";
import LoadBrokerStandardizationForm from "../../../forms/Regular/LoadBrokerStandardization";
import LoadChannelsForm from "../../../forms/Regular/LoadChannelsForm";
import LoadKeyBrokersFormDD from "../../../forms/Regular/LoadKeyBrokersFormDD";
import LoadOfficeMapForm from "../../../forms/Regular/LoadOfficeMapsForm";
import LoadTerritoryGroupForm from "../../../forms/Regular/LoadTerritoryGroupForm";
import LoadTerritoryMapsForm from "../../../forms/Regular/LoadTerritoryMapsForm";
import LoadVerifiedAddresses from "../../../forms/Regular/LoadVerifiedAddresses";
import LoadZoneMapsForm from "../../../forms/Regular/LoadZoneMapsForm";
import MFLoadZipCodeDataForm from "../../../forms/Regular/MFLoadZipCodeDataForm";
import PreprocessingForm from "../../../forms/Regular/PreprocessingForm";
import PreprocessingSecondaryFilesForm from "../../../forms/Regular/PreprocessingSecondaryFilesForm";
import ProductStandardsWrapperGenericLoadersForm from "../../../forms/Regular/ProductStandardsWrapperGenericLoadersForm";
import PublishClientExclusionsFormDD from "../../../forms/Regular/PublishAthenaClientExclusionsFormDD";
import PublishAthenaTransportDataForm from "../../../forms/Regular/PublishAthenaTransportDataForm";
import PublishAthenaTransportDataFormStaging from "../../../forms/Regular/PublishAthenaTransportDataFormStaging";
import SISSyncForm from "../../../forms/Regular/SISSyncForm";
import TerrServiceTerritoryAssignmentForm from "../../../forms/Regular/TerrServiceTerritoryAssignmentForm";
import TerrServiceTerritoryExportForm from "../../../forms/Regular/TerrServiceTerritoryExportForm";
import TerrServiceTerritoryMapsForm from "../../../forms/Regular/TerrServiceTerritoryMapsForm";
import TerrServiceTerritoryPublishMapForm from "../../../forms/Regular/TerrServiceTerritoryPublishMapForm";
import TerrServiceTerritoryQAImpactReportForm from "../../../forms/Regular/TerrServiceTerritoryQAImpactReportForm";

import AnnDashboardFormV2DD from "../../../forms/v2/ann/AnnDashBoardDD";
import AnnMissingZipsFormV2 from "../../../forms/v2/ann/AnnMissingZips";
import AnnMissingZipsDD from "../../../forms/v2/ann/AnnMissingZipsDD";
import AnnOTTFormV2DD from "../../../forms/v2/ann/AnnOTTDD";
import ANNSIIdentifierMappingDD from "../../../forms/v2/ann/AnnSIIdentifierMappingDD";
import AnnStandardFeedFormV2DD from "../../../forms/v2/ann/AnnStandardFeedDD";
import AnnStandardReportFormV2DD from "../../../forms/v2/ann/AnnStandardReportDD";
import AnnUnclassifiedBDsDDForm from "../../../forms/v2/ann/AnnUnclassifiedBDsDD";
import AnnVettingReportFormV2 from "../../../forms/v2/ann/AnnVettingReport";
import AnnWarningBreakdownFormV2 from "../../../forms/v2/ann/AnnWarningBreakdown";
import { backendType } from "../../../state/application";

// Discovery Data

// Selection flow: Action (category) -> Study -> Task Type

const lmsActions = [
  { id: "preprocessing", label: "Pre-processing" },
  { id: "reporting", label: "Reporting" },
  { id: "loading", label: "Loading" },
  { id: "dataset_curation", label: "Dataset Curation" },
];

const diActions = [
  { id: "reporting", label: "Reporting" },
  { id: "loading", label: "Loading" },
];

export const actions = (backendType === "LMS") ? lmsActions : diActions;

export const reportTypes = [{ id: "internal_reports", label: "INTERNAL" }, { id: "external_reports", label: "EXTERNAL" }, { id: "misight_reports", label: "MISIGHT" }];
export const preWarmreportType = [{ id: "misight_reports", label: "MISIGHT" }];
const lmsStudyTypes = [
  { id: "mf", label: "MF", hasClientProfiles: false },
  { id: "ann", label: "ANN", hasClientProfiles: true, fileLoadMonitor: true },
  { id: "ann_mf", label: "ANN + MF" },
  { id: "sma", label: "SMA", hasClientProfiles: false },
  { id: "all", label: "ALL", internalOnly: true },
];

export const lmsPreWarmStudyType = [
  { id: "ann", label: "ANN", hasClientProfiles: true, fileLoadMonitor: true },
];

export const diStudyTypes = [
  { id: "territory_service", label: "Territory Service", internalOnly: true },
];

export const studyTypes = (backendType === "LMS") ? lmsStudyTypes : diStudyTypes;

export const annStudyTypes = studyTypes.filter((study) => !["mf", "sma"].includes(study.id));

export const lmsTaskTypes = [
  // --- Preprocessing ---
  { action: "preprocessing", study: "ann", id: "preprocessing_ann", label: "ANN Preprocessing" },
  { action: "preprocessing", study: "ann", id: "preprocessing_v2", label: "ANN Preprocessing V2" },
  { action: "preprocessing", study: "all", id: "preprocessing_secondary_files", label: "Preprocessing Secondary files", all_clients_only: true },
  // --- Reporting ---
  // MF
  { action: "reporting", study: "all", id: "preprocessing_lms_dd_cross_ref", label: "Generate LMS-DD Cross Reference File", all_clients_only: true, reportType: "external_reports" },
  // ANN
  { action: "reporting", study: "ann", id: "reporting_ann_dashboard_v2", label: "ANN Dashboard", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_si_identifier_mapping", label: "ANN SI Identifier Mapping", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_misight_qa", label: "ANN Misight QA Report", reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_internal_terr_map_missing_zips_sales", label: "ANN Missing Zips", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann_mf_client_view", label: "ANN-MF Create Materialized Client View", reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann", label: "ANN Publish consortium table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann_mf", label: "ANN-MF Publish consortium table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann_legacy", label: "ANN Publish legacy tables", all_clients_only: true, dev_only_task: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann_territory", label: "ANN Publish territory table", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_vetting_report", label: "ANN LMS Vetting Report", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_warning_breakdown", label: "ANN Warning Breakdown", multiple_clients_selection: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_unclassified_bds", label: "ANN Unchannelized Brokers", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_general_data_dump", label: "ANN General Data Dump", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_product_name_breakdown", label: "ANN Product Name Breakdown", multiple_clients_selection: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_generic_exporter", label: "ANN Generic table exporter", all_clients_only: true },
  { action: "reporting", study: "ann", id: "reporting_ann_dd_general_data_dump", label: "ANN General Data Dump (DD)", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_standard_feed_v2_discovery", label: "ANN Standard Feed (DD)", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_dd_ann_dashboard_v2", label: "ANN Dashboard (DD)", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_dd_identifier_mapping", label: "ANN Discovery Identifier Mapping", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann_discovery", label: "ANN Publish consortium table (DD)", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann_territory_discovery", label: "ANN Publish territory table (DD)", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann_exclusions_discovery", label: "ANN Publish exclusions tables (DD)", all_clients_only: true, reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "dataintegration_ann_client_view_discovery", label: "ANN Create Materialized Client View (DD)", reportType: "misight_reports" },
  { action: "reporting", study: "ann", id: "reporting_dd_ann_internal_terr_map_missing_zips_sales", label: "ANN Missing Zips (DD)", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_dd_ann_unclassified_bds", label: "ANN Unchannelized Brokers (DD)", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_ott_v2_discovery", label: "ANN OTT (DD)", reportType: "external_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_standard_report_discovery", label: "ANN Standard Report (v2) (DD)", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_standard_report_client_specific_discovery", label: "ANN Standard Report - Client Specific (v2) (DD)", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_internal_terr_map_conflicts_discovery", label: "ANN Territory Conflicts - Territory Map (DD)", reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_internal_channel_map_conflicts_discovery", label: "ANN VA BD Channel Conflicts (DD)", multiple_clients_selection: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_matching_qa_report_discovery", label: "ANN Matching Reports (DD)", all_clients_only: true, reportType: "internal_reports" },
  { action: "reporting", study: "ann", id: "reporting_ann_missing_branches_discovery", label: "ANN Missing Branches (DD)", all_clients_only: true, reportType: "internal_reports" },
  // TODO: Uncomment Below Dashboard Jobs once new updates are released
  // { action: "reporting", study: "ann", id: "reporting_ann_lms_qa_dashboard", label: "ANN LMS QA Dashboard", reportType: "internal_reports", all_clients_only: true },
  { action: "reporting", study: "ann", id: "reporting_ann_misight_qa_dashboard", label: "ANN Misight QA Dashboard", reportType: "misight_reports", all_clients_only: true },
  // --- Loading ---
  // TODO: Change backend to be loading type instead of preprocessing
  // ALL
  { action: "loading", study: "all", id: "dataintegration_create_lock_partition", label: "Create Athena Lock Partition", all_clients_only: true },

  // ANN
  { action: "loading", study: "ann", id: "preprocessing_generic_loader", label: "ANN Generic table loaders", all_clients_only: true },
  { action: "loading", study: "ann", id: "preprocessing_load_office_map", label: "ANN Office Maps" },
  { action: "loading", study: "ann", id: "preprocessing_load_key_broker", label: "ANN Load Key Brokers" },
  { action: "loading", study: "ann", id: "preprocessing_load_territory_groups", label: "ANN Territory Group Loader" },
  { action: "loading", study: "ann", id: "preprocessing_channel_loader", label: "ANN Channel Loader" },
  { action: "loading", study: "ann", id: "preprocessing_load_territory_map", label: "ANN Territory Map Loader" },
  { action: "loading", study: "ann", id: "preprocessing_load_zone_map", label: "ANN Load Zone Map" },
  { action: "loading", study: "ann", id: "preprocessing_load_sales_ann", label: "ANN Sales Loader" },
  { action: "loading", study: "ann", id: "preprocessing_load_office_map_discovery", label: "ANN Office Maps (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_channel_loader_discovery", label: "ANN Channel Loader (DD) " },
  { action: "loading", study: "ann", id: "preprocessing_load_territory_map_discovery", label: "ANN Territory Map Loader (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_load_territory_groups", label: "ANN Territory Group Loader (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_load_zone_map", label: "ANN Load Zone Map (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_generic_loader_discovery", label: "ANN Generic Loader (DD)", all_clients_only: true },
  { action: "loading", study: "ann", id: "preprocessing_load_key_broker_discovery", label: "ANN Load Key Brokers (DD)" },
  { action: "loading", study: "ann", id: "preprocessing_ann_load_va_product_types_discovery", label: "ANN Load VA Product Types (DD)", all_clients_only: true },
  // ANN+MF
  { action: "bulk_loading", study: "ann_mf", id: "preprocessing_bulk_upload_client_profile_exclusions", label: "Bulk Upload Client Profile Exclusions" },
  { action: "bulk_loading", study: "ann_mf", id: "preprocessing_dd_bulk_upload_client_profile_exclusions", label: "Bulk Upload Client Profile Exclusions (DD)" },
  { action: "bulk_loading", study: "ann_mf", id: "preprocessing_dd_bulk_upload_client_profile_inclusions", label: "Bulk Upload Client Profile Inclusions (DD)" },
  { action: "bulk_loading", study: "ann", id: "preprocessing_dd_bulk_upload_consortium_exclusions", label: "Bulk Upload Consortium Exclusions (DD)" },
  // ALL
  { action: "loading", study: "ann", id: "preprocessing_add_new_client", label: "ANN Add/Rename Client", dev_only_task: true, all_clients_only: true },

  // dataset_curation
  { action: "dataset_curation", study: "ann", id: "preprocessing_broker_standardization", label: "ANN Load VA_FDS_BROKER_IDS", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_broker_standardization_client", label: "ANN Load VA_CLIENT_SPECIFIC_IDS", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_load_mf_zip_code_data", label: "ANN Load Zip Code Data", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_load_va_product_types", label: "ANN Load VA Product Types", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_load_verified_address", label: "ANN Load Verified Addresses", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_share_class", label: "ANN Share Class Standardization", all_clients_only: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_product_standards_loader", label: "ANN Product Standards loaders", allow_all_clients: true },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_populate_sales_totals_discovery", label: "ANN Populate Sales Totals - Client Specific (DD)" },
  { action: "dataset_curation", study: "ann", id: "preprocessing_ann_populate_sales_totals_all_clients_discovery", label: "ANN Populate Sales Totals - All Clients (DD)", all_clients_only: true },
  { action: "dataset_curation", study: "ann_mf", id: "preprocessing_locked_dataset", label: "Dataset Locking", all_clients_only: true },
  { action: "dataset_curation", study: "all", id: "preprocessing_sis_sync", label: "SIS Sync", all_clients_only: true },
  { action: "dataset_curation", study: "all", id: "preprocessing_dd_sync", label: "Discovery Data Sync", all_clients_only: true },
  { action: "dataset_curation", study: "all", id: "sync_aurora_sync", label: "Aurora Data Sync", all_clients_only: true, dev_only_task: true },
  { action: "dataset_curation", study: "all", id: "sync_athena_sync", label: "Athena Data Sync", all_clients_only: true, dev_only_task: true },
  { action: "dataset_curation", study: "all", id: "dataintegration_transport_discovery_data", label: "MISight Discovery Periodic Data Loader", reportType: "misight_reports", one_client_only: "DISCOVERYDATA" },
  { action: "dataset_curation", study: "all", id: "dataintegration_transport_discovery_data_staging", label: "Discovery Staging Periodic Dataloader", reportType: "misight_reports", one_client_only: "DISCOVERYDATA" },
  { action: "dataset_curation", study: "all", id: "dataintegration_bs_historical_plan_data", label: "MISight Brightscope Periodic Data Loader", reportType: "misight_reports", one_client_only: "BRIGHTSCOPE" },
  { action: "dataset_curation", study: "all", id: "preprocessing_consortium_sync", label: "Sync Consortium Exclusions", all_clients_only: "true" },
  // Analytics
  { action: "loading", study: "ann", id: "analytics_ann_gp_score_predict", label: "ANN Growth Potential Score Prediction", all_clients_only: true },
];

export const annLmsTaskTypes = lmsTaskTypes.filter((task) => !["mf", "sma"].includes(task.study));

export const lmsPreWarmTaskType = [
  { action: "reporting", study: "ann", id: "misight_prewarm_cache", label: "ANN MISight Prewarm Cache", reportType: "misight_reports" },
];

export const diTaskTypes = [
  { action: "reporting", study: "territory_service", id: "territory_assignment", label: "Territory Assignment" },
  { action: "loading", study: "territory_service", id: "territory_rule_generation", label: "Territory Maps" },
  { action: "loading", study: "territory_service", id: "territory_qa_impact_report", label: "Territory QA Impact Report" },
  { action: "loading", study: "territory_service", id: "territory_rule_publishing", label: "Territory Maps Publishing" },
  { action: "loading", study: "territory_service", id: "territory_lms_territory_export", label: "LMS Territory Export Job", multiple_clients: true },

];

export const taskTypes = (backendType === "LMS") ? lmsTaskTypes : diTaskTypes;

export default {
  // --- PREPROCESSING ---

  // ALL STUDIES
  preprocessing: PreprocessingForm,
  preprocessing_ann: PreprocessingForm,
  preprocessing_v2: PreprocessingForm,
  preprocessing_secondary_files: PreprocessingSecondaryFilesForm,
  dataintegration_ann_client_view_discovery: ClientMaterializedViewFormDD,
  dataintegration_ann_mf_client_view: AnnMFClientMaterializedViewForm,
  dataintegration_ann: PublishAthenaConsortiumForm,
  dataintegration_ann_discovery: PublishAthenaConsortiumFormDD,
  dataintegration_ann_legacy: PublishANNLegacyForm,
  dataintegration_ann_mf: PublishAthenaConsortiumForm,
  dataintegration_ann_territory_discovery: PublishAthenaTerritoryFormDD,
  dataintegration_ann_exclusions_discovery: PublishClientExclusionsFormDD,
  dataintegration_transport_discovery_data: PublishAthenaTransportDataForm,
  dataintegration_transport_discovery_data_staging: PublishAthenaTransportDataFormStaging,
  dataintegration_bs_historical_plan_data: PublishAthenaTransportDataForm,
  dataintegration_create_lock_partition: CreateAthenaLockPartitionForm,
  misight_prewarm_cache: MISightPrewarmCacheForm,
  preprocessing_sis_sync: SISSyncForm,
  preprocessing_dd_sync: DDSyncForm,
  sync_athena_sync: AthenaSyncForm,
  preprocessing_lms_dd_cross_ref: LMSDDCrossReference,

  // --- REPORTING ---
  // ANN
  reporting_ann_standard_feed: ANNStandardFeedForm,
  reporting_ann_standard_feed_v2_discovery: AnnStandardFeedFormV2DD,
  reporting_ann_standard_report_discovery: AnnStandardReportFormV2DD,
  reporting_ann_standard_report_client_specific_discovery: AnnStandardReportFormV2DD,
  reporting_matching_qa_report_discovery: DDMatchingQAReport,

  reporting_ann_ott: ANNOTTForm,
  reporting_ann_ott_v2_discovery: AnnOTTFormV2DD,
  reporting_ann_dashboard: ANNDashboardForm,
  reporting_dd_ann_dashboard_v2: AnnDashboardFormV2DD,
  reporting_ann_dd_identifier_mapping: ANNSIIdentifierMappingDD,
  v2_reporting_ann_si_identifier_mapping: AnnSiIdentifierMappingForm,
  reporting_ann_misight_qa: AnnMisightQAReportForm,
  reporting_ann_internal_terr_map_conflicts: AnnInternalTerrConflictFromMapForm,
  reporting_ann_internal_terr_map_conflicts_discovery: AnnInternalTerrConflictFromMapFormDD,
  reporting_ann_internal_terr_map_missing_zips_sales: AnnMissingZipsFormV2,
  reporting_dd_ann_internal_terr_map_missing_zips_sales: AnnMissingZipsDD,
  reporting_ann_vetting_report: AnnVettingReportFormV2,
  reporting_ann_warning_breakdown: AnnWarningBreakdownFormV2,
  reporting_ann_internal_channel_map_conflicts: AnnVABDChannelConflictsForm,
  reporting_ann_internal_channel_map_conflicts_discovery: AnnVABDChannelConflictsFormDD,
  reporting_dd_ann_unclassified_bds: AnnUnclassifiedBDsDDForm,
  reporting_ann_dd_general_data_dump: AnnGeneralDataDumpDD,
  reporting_ann_product_name_breakdown: ANNProductNameBreakdownForm,
  reporting_ann_lms_qa_dashboard: ANNLMSQADashboardForm,
  reporting_ann_misight_qa_dashboard: ANNMisightQADashboardForm,
  reporting_ann_missing_branches_discovery: DDMissingBranches,

  // TERRITORIES
  territory_assignment: TerrServiceTerritoryAssignmentForm,

  // --- LOADING --
  // MF or ANN
  preprocessing_load_territory_groups: LoadTerritoryGroupForm,
  preprocessing_channel_loader: LoadChannelsForm,
  preprocessing_load_territory_map: LoadTerritoryMapsForm,
  preprocessing_load_office_map: LoadOfficeMapForm,
  preprocessing_load_verified_address: LoadVerifiedAddresses,
  preprocessing_broker_standardization: LoadBrokerStandardizationForm,
  preprocessing_add_new_client: AddNewClient,
  // MF
  preprocessing_load_mf_zip_code_data: MFLoadZipCodeDataForm,
  preprocessing_load_sales_ann: LoadAnnSalesForm,

  // ANN
  preprocessing_share_class: AnnLoadShareClassStandardsForm,
  preprocessing_ann_broker_standardization_client: LoadBrokerStandardizationForm,
  preprocessing_ann_product_standards_loader: ProductStandardsWrapperGenericLoadersForm,
  preprocessing_load_zone_map: LoadZoneMapsForm,
  preprocessing_ann_populate_sales_totals_discovery: ANNSalesTotalsSelectivelyDD,
  preprocessing_ann_populate_sales_totals_all_clients_discovery: ANNSalesTotalsSelectivelyDD,
  preprocessing_channel_loader_discovery: ANNLoadChannelsFormDD,
  preprocessing_load_office_map_discovery: ANNLoadOfficeMapFormDD,
  preprocessing_load_territory_map_discovery: ANNLoadTerritoryMapsFormDD,
  preprocessing_generic_loader_discovery: GenericLoaderFormDD,
  preprocessing_load_key_broker_discovery: LoadKeyBrokersFormDD,
  preprocessing_ann_load_va_product_types_discovery: AnnLoadVAProductTypesDD,

  // ANN+MF
  preprocessing_locked_dataset: DatasetLockingForm,
  // TERRITORIES
  territory_rule_generation: TerrServiceTerritoryMapsForm,
  territory_qa_impact_report: TerrServiceTerritoryQAImpactReportForm,
  territory_rule_publishing: TerrServiceTerritoryPublishMapForm,
  territory_lms_territory_export: TerrServiceTerritoryExportForm,
  // ALL
  sync_aurora_sync: AuroraSyncForm,
  // Analytics
  analytics_ann_gp_score_predict: AnnGPSForm,
  reporting_generic_exporter: GenericExporterForm,
};
